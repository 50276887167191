import { ReactNode, useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { DocumentPlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { isArray } from "lodash";
import { ReportDocIds, ReportLanguage, ReportStatus, ReportType } from "@parallel/vertex/enums/report.enums";
import { ReportStudentDetails, ServiceReportType } from "@parallel/vertex/types/report.types";
import { ExtendedStudentUser } from "@parallel/vertex/types/user/student.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { getReportReviewersByReportType } from "@parallel/vertex/util/report.util";
import { LoadingModal } from "@/components/common/content/LoadingModal";
import { Link } from "@/components/common/elements/Link";
import { Button, DEFAULT_BUTTON_CLASS } from "@/components/common/elements/button/Button";
import { FormStep } from "@/components/common/elements/form/FormStep";
import { Checkbox } from "@/components/common/elements/input/Checkbox";
import CurrentUserClientSelect from "@/components/common/elements/select/CurrentUserClientSelect";
import Select from "@/components/common/elements/select/Select";
import CommonScreenLayout from "@/components/common/windows/CommonScreenLayout";
import config from "@/config";
import { AlertStoreContext, ApiStoreContext, UserStoreContext, loggerContext } from "@/store";
import { classNames, initLogger } from "@/utils";
import { currTimeOnDate, shortTimeZone } from "@/utils/datetime";
import { useLoggedRedirect } from "@/utils/redirect.utils";

const logger = initLogger("CreateAssessmentReport", loggerContext);

const SELECT_STYLE_CLASSES = "xs:w-[180px] sm:w-[360px] h-[42px]";

const CreateAssessmentReport = () => {
  const { reportApi } = useContext(ApiStoreContext);
  const alertStore = useContext(AlertStoreContext);
  const { currentUser } = useContext(UserStoreContext);
  const { userId } = currentUser || {};

  const [isCreating, setIsCreating] = useState(false);
  const [selectedClient, setSelectedClient] = useState<ExtendedStudentUser>();
  const [consentSignedDate, setConsentSignedDate] = useState<Date | null>(new Date());
  const [dueDate, setDueDate] = useState<Date | null>(new Date());
  const [selectedReviewerUserId, setSelectedReviewerUserId] = useState<string>();
  const [selectedReportType, setSelectedReportType] = useState<ServiceReportType>();
  const [isEnglish, setIsEnglish] = useState(true);

  const selectedReportLanguage = isEnglish ? ReportLanguage.English : ReportLanguage.Billingual;

  const availableReviewers = selectedReportType
    ? getReportReviewersByReportType(config, selectedReportType.reportType)
    : [];

  const [{ result: allReports }, { execute: fetchAllReports }] = useAsync(() =>
    userId
      ? logger
          .wrapOperation("fetchAdminReports", reportApi.getByAdminUser(userId), undefined, reports => ({
            ownedReportIds: reports.owned.map(r => r.report.id),
            sharedReportIds: reports.shared.map(r => r.report.id),
          }))
          .catch(e => {
            alertStore.setFailedProcess("fetch reports", e);
          })
      : Promise.resolve({ shared: [], owned: [] }),
  );

  useMountEffect(() => {
    logger.mount("AssessmentReportHome");
    fetchAllReports();
  });

  const [selectedStudentDetails, setSelectedStudentDetails] = useState<ReportStudentDetails>();

  useEffect(() => {
    if (!selectedClient) return;
    setSelectedStudentDetails(undefined);
    reportApi.getReportStudentDetails(selectedClient.userId).then(d => {
      setSelectedStudentDetails(d);
      d.enrolledReportType?.consentSignedAt &&
        setConsentSignedDate(d.enrolledReportType.consentSignedAt.startOf("day").plus({ hours: 12 }).toJSDate());
    });
  }, [selectedClient]);

  const inProgressClientIds = mapExists(allReports?.owned, r =>
    r.report.status !== ReportStatus.Complete ? r.report.clientUserId : undefined,
  );

  const handleClientClear = () => {
    setSelectedClient(undefined);
    setSelectedReviewerUserId(undefined);
  };

  const redirect = useLoggedRedirect(logger);

  const templateDocId = selectedReportType ? ReportDocIds[selectedReportType.reportType][selectedReportLanguage] : "";

  const isClientInProgress = inProgressClientIds.some(c => c === selectedClient?.userId);

  const isClientValid = selectedClient && !isClientInProgress && selectedStudentDetails?.enrolledReportType;

  const isReportValid =
    isClientValid && !!dueDate && !!consentSignedDate && !!selectedReportType && !!selectedReviewerUserId;

  let warning: ReactNode = "";
  if (selectedClient) {
    if (isClientInProgress) {
      warning =
        "You already have a report in progress for this student. Please complete the existing report before starting a new report for this student.";
    } else if (selectedStudentDetails && !selectedStudentDetails.enrolledReportType) {
      warning = (
        <p>
          This student does not have an assessment service line. Please go to{" "}
          <Link href={`${config.pathwayClientUrl}/user/student/${selectedClient.userId}`}>the student page</Link> and
          add an assessment service line to this student before starting their report.
        </p>
      );
    } else if (!selectedReportType) {
      warning = "Report type is required";
    } else if (!consentSignedDate) {
      warning = "Consent signed date is required";
    } else if (!dueDate) {
      warning = "Report due date is required";
    } else if (!selectedReviewerUserId) {
      warning = "Reviewer is required";
    } else if (
      selectedReportType &&
      selectedStudentDetails &&
      selectedReportType.serviceLine.id !== selectedStudentDetails.enrolledReportType?.serviceLine.id
    ) {
      const firstSentence = "The selected assessment type does not match the student's assessment service line.";
      const currentServiceName = selectedStudentDetails?.enrolledReportType?.serviceLine.title;
      warning = currentServiceName
        ? `${firstSentence} On report creation we will update the student from service '${currentServiceName}' to '${selectedReportType.serviceLine.title}'.`
        : firstSentence;
    }
  }

  const startReport = async () => {
    if (!selectedClient || !consentSignedDate || !userId || !dueDate || !selectedReportType) return;
    try {
      setIsCreating(true);
      const reportCreationRequest = {
        clientUserId: selectedClient.userId,
        startedByUserId: userId,
        type: selectedReportType.reportType,
        serviceLineId: selectedReportType.serviceLine.id,
        language: selectedReportLanguage,
        consentSignedAt: consentSignedDate,
        reviewerUserId: selectedReviewerUserId,
        reportDueDate: dueDate,
      };
      const report = await logger.wrapOperation(
        "createReport",
        reportApi.create(reportCreationRequest),
        reportCreationRequest,
      );
      setIsCreating(false);
      redirect(`/assessment/report/${report.id}`, "new report");
    } catch (e) {
      setIsCreating(false);
      alertStore.setFailedProcess("create assessment report", e as Error);
    }
  };

  return (
    <CommonScreenLayout headerText="New Report">
      <div className="w-full flex flex-col py-4">
        <FormStep label="Student">
          <div className={`${SELECT_STYLE_CLASSES} my-2`}>
            {!selectedClient ? (
              <div className="w-full flex flex-col items-center">
                <CurrentUserClientSelect
                  userSelected={setSelectedClient}
                  onShowToggle={isShowing => isShowing && setSelectedClient(undefined)}
                  focusOnMount={true}
                  containerClasses="border-slate-300 border h-[42px]"
                  inputClasses="border-0"
                />
              </div>
            ) : (
              <div className="w-full px-4 bg-white rounded-md border border-gray-300 flex flex-row place-items-center justify-between h-[42px]">
                <div className="text-md">{selectedClient?.fullName}</div>
                <XMarkIcon className="w-4 h-4 cursor-pointer" onClick={() => handleClientClear()} />
              </div>
            )}
          </div>
        </FormStep>
        <FormStep label="Report Type">
          <div className="w-full py-2">
            <div className="flex flex-col gap-2">
              <Select
                label="type-select"
                options={mapExists(selectedStudentDetails?.allReportTypes, t => ({
                  name:
                    t.reportType === ReportType.SocialEmotional
                      ? `${t.reportType} (${t.serviceLine.title.split(" ").slice(0, 2).join(" ")})`
                      : t.reportType,
                  value: t.serviceLine.id,
                }))}
                value={selectedReportType?.serviceLine.id}
                placeholder={
                  selectedStudentDetails?.allReportTypes || !selectedClient
                    ? "Select a report type..."
                    : "Loading report types..."
                }
                className={SELECT_STYLE_CLASSES}
                onChange={serviceLineId =>
                  setSelectedReportType(
                    selectedStudentDetails?.allReportTypes?.find(t => t.serviceLine.id === serviceLineId),
                  )
                }
                disabled={!isClientValid || !selectedStudentDetails?.allReportTypes}
              />
              <div className="flex flex-row space-x-16 items-center">
                <Checkbox
                  id="report-language"
                  label="Is Bilingual?"
                  isChecked={!isEnglish}
                  toggle={() => setIsEnglish(!isEnglish)}
                />
                <a
                  href={`https://docs.google.com/document/d/${templateDocId}/view`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-ocean underline italic"
                >
                  Preview Template
                </a>
              </div>
            </div>
          </div>
        </FormStep>
        <FormStep label={`Consent Signed Date (${shortTimeZone(new Date())})`}>
          <div className={`my-2 flex flex-col `}>
            <DatePicker
              onChange={value => {
                const date = isArray(value) ? value[0] : value;
                setConsentSignedDate(date && currTimeOnDate(date));
              }}
              className={SELECT_STYLE_CLASSES}
              disabled={!isClientValid || !selectedStudentDetails}
              value={consentSignedDate}
            />
          </div>
        </FormStep>
        <FormStep label={`Report Due Date (${shortTimeZone(new Date())})`}>
          <div className={`my-2 w-full flex flex-col`}>
            <DatePicker
              onChange={value => {
                const date = isArray(value) ? value[0] : value;
                setDueDate(date && currTimeOnDate(date));
              }}
              disabled={!isClientValid}
              className={SELECT_STYLE_CLASSES}
              value={dueDate}
            />
          </div>
        </FormStep>
        <FormStep label="Reviewer" lastStep={true}>
          <div className="w-full py-2">
            <Select
              className={SELECT_STYLE_CLASSES}
              label="reviewer-select"
              placeholder="Select a reviewer..."
              options={availableReviewers.map(r => ({ name: r.name, value: r.userId }))}
              value={selectedReviewerUserId}
              disabled={!isClientValid || availableReviewers.length < 2}
              onChange={selected => setSelectedReviewerUserId(selected)}
            />
          </div>
        </FormStep>
      </div>

      {warning && <div className="py-4 px-1 visible font-medium tracking-wide text-red-500 text-sm">{warning}</div>}
      <div className="py-4">
        <Button
          text="Create Report"
          icon={<DocumentPlusIcon />}
          className={classNames(DEFAULT_BUTTON_CLASS)}
          styleOverrides={{ width: "20%" }}
          disabled={!isReportValid}
          onClick={startReport}
        />
      </div>

      {isCreating && <LoadingModal />}
    </CommonScreenLayout>
  );
};

export default CreateAssessmentReport;

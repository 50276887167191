import { flatten, isArray, isNull } from "lodash";

export const sortNumerically = <A>(a: A[], getNum: (x: A) => number | undefined, direction: "asc" | "desc" = "asc") =>
  [...a].sort((x, y) => ((getNum(x) || 0) - (getNum(y) || 0)) * (direction === "asc" ? 1 : -1));

export const getSequence = (first: number, last: number, countBy: number = 1): number[] => {
  const seq: number[] = [];
  for (let i = first; i <= last; i += countBy) {
    seq.push(i);
  }
  return seq;
};

export const mapSequence = <A>(count: number, fn: (i: number) => A, start: number = 0, countBy: number = 1): A[] => {
  const seq: A[] = [];
  for (let i = 0; i < count; i += 1) {
    seq.push(fn(start + i * countBy));
  }
  return seq;
};

type Maybe<A> = A | undefined | null | false | "";
type MaybeList<A> = Maybe<Maybe<A>[]>;

const isDefined = <A>(a: Maybe<A>): a is A => a !== undefined && a !== null && a !== false && a !== "";

export const filterExists = <A>(as: MaybeList<A>, filter: (a: A) => boolean = () => true): A[] =>
  (as || []).flatMap(a => (isDefined(a) && filter(a) ? [a] : []));

export const mapExists = <A, B>(
  as: MaybeList<A>,
  fn: (a: A, i: number) => Maybe<B>,
  filter: (a: A) => boolean = () => true,
): B[] => filterExists(filterExists(as).filter(filter).map(fn));

export const mapExistsAsync = async <A, B>(
  as: MaybeList<A>,
  fn: (a: A, i: number) => Promise<Maybe<B>>,
  filter: (a: A) => boolean = () => true,
): Promise<B[]> => {
  const maybes = await Promise.all(filterExists(as).filter(filter).map(fn));
  return filterExists(maybes);
};

export const flatMapExists = <A, B>(as: Maybe<A[]>, fn: (a: A) => Maybe<B[]>): B[] => flatten(mapExists(as, fn));

export const groupByArray = <A extends Record<string, any>>(
  as: A[],
  getKeys: (a: A) => string[],
): Record<string, A[]> =>
  as.reduce((prevGroups, nextA) => {
    const keys = getKeys(nextA);
    return {
      ...prevGroups,
      ...keys.reduce(
        (prevSubgroups, nextKey) => ({
          ...prevSubgroups,
          [nextKey]: [...(prevGroups[nextKey] || []), nextA],
        }),
        {} as Record<string, A[]>,
      ),
    };
  }, {} as Record<string, A[]>);

export const toArray = <A>(a: A | A[] | null): A[] => {
  if (isArray(a)) return a;
  if (isNull(a)) return [];
  return [a];
};

export const updateAtIndex = <A extends object>(as: A[], index: number, update: Partial<A>) =>
  as.map((a, i) => (i === index ? { ...a, ...update } : a));

export const removeAtIndex = <A>(as: A[], index: number, currentIndex: number = index): [A[], number | undefined] => {
  const updated = as.filter((_, i) => i !== index);
  const newIndex =
    index < currentIndex || (index === currentIndex && index === as.length - 1) ? currentIndex - 1 : currentIndex;

  return [updated, newIndex >= 0 ? newIndex : undefined];
};

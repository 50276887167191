import axiosDefault, { AxiosError, AxiosInstance, AxiosResponse, AxiosStatic, InternalAxiosRequestConfig } from "axios";
import { isArray, isObject, isString, mapValues } from "lodash";
import { DateTime } from "luxon";
import { NEXUS_ERROR_CODES, NEXUS_ERROR_CODE_KEY, NexusErrorCode } from "../enums/nexus.enums";
import { formatNounWithCount } from "./string.util";

export const errorHttpStatus = (error: any) => (error instanceof AxiosError ? error.response?.status : undefined);

export const isAuthError = (error: any) => errorHttpStatus(error) === 401;

export class NexusError extends Error {
  constructor(public error: AxiosError<any>) {
    super(error.message);
  }
  get status(): number | undefined {
    return this.error.response?.status;
  }
  get nexusErrorCode(): NexusErrorCode | undefined {
    const responseData: any = this.error.response?.data;
    if (!isObject(responseData)) return;
    return NEXUS_ERROR_CODES.find(c => c === (responseData as any)[NEXUS_ERROR_CODE_KEY]);
  }
}

export class FriendlyApiError extends Error {
  constructor(
    public apiError: any,
    msg: string,
  ) {
    super(msg);
  }
}

export class SignInError extends FriendlyApiError {
  isRateLimit: boolean = false;
  constructor(
    public apiError: any,
    throttle?: { attempts: number; timeoutMinutes: number; attemptCount: number },
  ) {
    super(apiError, "Sign in failed unexpectedly - try again or contact support.");
    if (apiError instanceof AxiosError) {
      if (apiError.response?.status === 401) {
        this.message = `Your username or password is incorrect. Try again, or click “Forgot your password?” below.`;
        const attemptsRemaining = throttle ? throttle.attempts - throttle.attemptCount + 1 : NaN;
        if (attemptsRemaining > 0) this.message += ` You have ${attemptsRemaining} attempts remaining.`;
      } else if (apiError.response?.status === 429 && throttle) {
        const minutesString = formatNounWithCount(throttle.timeoutMinutes, "minute");
        this.message = `You have exceeded the sign-in attempt limit. Click “Forgot your password?” below or try again in ${minutesString}.`;
        this.isRateLimit = true;
      } else if (apiError.response?.data?.message) {
        this.message = apiError.response.data.message;
      }
    }
  }
}

export class PasswordResetError extends FriendlyApiError {
  isCodeInvalid: boolean = false;
  constructor(public apiError: any) {
    super(apiError, "Password reset failed unexpectedly - try again or contact support.");
    if (apiError instanceof AxiosError) {
      if (apiError.response?.status === 401) {
        this.message = "Your password reset link has expired. Please request another code.";
        this.isCodeInvalid = true;
      } else if (apiError.response?.status === 400) {
        this.message =
          'The password you have submitted is not strong enough. For a stronger password, try adding unique characters and avoid common strings like "password" and "1234"';
      }
    }
  }
}

export abstract class NexusBaseAPI {
  protected instance: AxiosInstance;

  protected getAuthKey: () => string | undefined = () => undefined;
  protected handleAuthFailure = () => {};

  constructor(
    private options: { baseURL: string; parseTimestamps?: boolean; parseNexusErrors?: boolean },
    private axios: AxiosStatic = axiosDefault,
  ) {
    this.instance = axios.create({ baseURL: options.baseURL, withCredentials: true });
    this.setHandlers();
  }

  public setBaseUrl(baseURL: string) {
    this.instance = this.axios.create({ baseURL, withCredentials: true });
    this.setHandlers();
  }

  private setHandlers = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError);
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  };

  private handleRequest = (request: InternalAxiosRequestConfig) => {
    const authKey = this.getAuthKey();
    if (authKey) {
      request.headers.setAuthorization(`Bearer ${authKey}`);
    }
    if (this.options.parseTimestamps) {
      request.data = this.parseDateTimes(request.data);
      request.params = this.parseDateTimes(request.params);
    }
    return request;
  };

  private handleResponse = (response: AxiosResponse) => {
    if (this.options.parseTimestamps) {
      response.data = this.parseTimestamps(response.data);
    }
    return response;
  };

  private parseDateTimes = (data: any): any => {
    if (isArray(data)) return data.map(this.parseDateTimes);
    if (!isObject(data)) return data;
    return mapValues(data as any, value =>
      value instanceof DateTime ? value.toUTC().toISO() : this.parseDateTimes(value),
    );
  };

  private parseTimestamps = (data: any): any => {
    if (isArray(data)) return data.map(this.parseTimestamps);
    if (!isObject(data)) return data;
    return mapValues(data as any, value => {
      const parseResult = isString(value) && value.match(/^\d+-\d+-\d+T\d+:\d+/) ? DateTime.fromISO(value) : undefined;
      return parseResult?.isValid ? parseResult.toUTC() : this.parseTimestamps(value);
    });
  };

  private handleError = (error: any) => {
    if (isAuthError(error)) this.handleAuthFailure();
    return Promise.reject(this.options.parseNexusErrors && error instanceof AxiosError ? new NexusError(error) : error);
  };
}
